import React, { FC, useCallback, useEffect, useReducer, useState } from 'react'

import { FormInstance } from '../../../components/Form'

const dummy = () => {
    return
}

export interface ITariffRowContext {
    values?: Record<string, unknown>
    initialValues?: Record<string, unknown>
    onUpdateValue: (value: Record<string, unknown>) => void
    form?: FormInstance<Record<string, unknown>>
}

export interface ITariffRowContextProps {
    initialValues?: Record<string, unknown>
    form?: FormInstance<Record<string, unknown>>
}

export const TariffRowContext = React.createContext<ITariffRowContext>({
    values: {},
    initialValues: {},
    onUpdateValue: dummy,
})

export const TariffRowContextProvider: FC<ITariffRowContextProps> = ({
    children,
    initialValues,
    form,
}) => {
    const [values, setValues] = useState<Record<string, unknown> | undefined>(initialValues)

    const onUpdateValue = (value: Record<string, unknown>) => {
        setValues({ ...values, ...value })
        form?.setFieldsValue(value)
    }

    useEffect(() => {
        setValues(initialValues)
    }, [initialValues])

    return (
        <TariffRowContext.Provider
            value={{
                values,
                form,
                onUpdateValue,
            }}
        >
            {children}
        </TariffRowContext.Provider>
    )
}
