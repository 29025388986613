import { ETariffTypeTitle } from './types'

export const ItemsLabels = {
    [ETariffTypeTitle.Percent]: 'Процент от суммы операции',
    [ETariffTypeTitle.PercentPerYear]: 'Процентная ставка, % в год',
    [ETariffTypeTitle.Rubles]: 'Фиксированная сумма в рублях',
}

export const ItemsTitles = {
    [ETariffTypeTitle.Percent]: '%',
    [ETariffTypeTitle.PercentPerYear]: '% в год',
    [ETariffTypeTitle.Rubles]: 'рублей',
}

export const itemsPercent = [
    {
        key: ETariffTypeTitle.Percent,
        label: ItemsLabels[ETariffTypeTitle.Percent],
        title: ItemsTitles[ETariffTypeTitle.Percent],
        value: ETariffTypeTitle.Percent,
    },

    {
        key: ETariffTypeTitle.PercentPerYear,
        label: ItemsLabels[ETariffTypeTitle.PercentPerYear],
        title: ItemsTitles[ETariffTypeTitle.PercentPerYear],
        value: ETariffTypeTitle.PercentPerYear,
    },
]

export const itemsFix = [
    {
        key: ETariffTypeTitle.Percent,
        label: ItemsLabels[ETariffTypeTitle.Percent],
        title: ItemsTitles[ETariffTypeTitle.Percent],
        value: ETariffTypeTitle.Percent,
    },

    {
        key: ETariffTypeTitle.Rubles,
        label: ItemsLabels[ETariffTypeTitle.Rubles],
        title: ItemsTitles[ETariffTypeTitle.Rubles],
        value: ETariffTypeTitle.Rubles,
    },
]
