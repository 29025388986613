import {
    FeeType,
    IRateBase,
    IRateBaseFront,
    ITariffPlane,
    rateBackToFront,
    tariffPlaneTypeTranslate,
} from '@dltru/dfa-models'

import { FC } from 'react'
import { Button } from '../../../button'
import { Modal } from '../../../components/Modal'
import { DateTimeCell, Table } from '../../../Table'
import { getTariffCode } from '../helper'
import { getTariffViewGridConfig } from '../TariffGridConfig'
import { mapToDatasource } from './utils'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    data: ITariffPlane
    fees: FeeType[]
}
export const TariffInfoModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    data,
    fees,
}) => {
    let dataSource: IRateBaseFront[] = []
    if (data?.active_grid?.rates) {
        dataSource = mapToDatasource(data.active_grid.rates.map(rateBackToFront), fees)
    }

    return (
        <Modal
            width={1136}
            visible={isModalVisible}
            footer={[
                <Button borderRadius={12} onClick={() => setIsModalVisible(false)}>
                    Закрыть
                </Button>,
            ]}
            title={
                <>
                    {tariffPlaneTypeTranslate[data.type]} тарифный план {getTariffCode(data)}{' '}
                    действует с {DateTimeCell(data.active_grid?.active_from)}
                </>
            }
            onCancel={() => setIsModalVisible(false)}
        >
            <Table
                columns={getTariffViewGridConfig()}
                dataSource={dataSource}
                rowKey="fee_code"
                uneditable
                noPagination
            />
        </Modal>
    )
}
