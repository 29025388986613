import { ETariffTypeTitle, IRateBase, IRateBaseFront } from '@dltru/dfa-models'
import { ItemsTitles } from './consts'

import { EditableTariffColumnType, GridConfigProps, TariffColumnType } from './types'
import {
    renderEditableNumber,
    renderError,
    renderName,
    renderNumber,
    renderPenniesNumber,
    renderPercentSwitcher,
    renderPercentSwitcherMax,
    renderPercentSwitcherMin,
    renderPureName,
} from './utils'

export const renderValueWithType = (type?: ETariffTypeTitle, value?: string) => {
    if (!type || !value) {
        return <span>0</span>
    }

    const renderedType = ItemsTitles[type]

    return (
        <>
            <span>{value}</span> <span>{renderedType}</span>
        </>
    )
}

export const getTariffGridConfig = ({
    feeNames,
    isEditable,
    onTypeClick,
}: GridConfigProps): EditableTariffColumnType[] => [
    {
        title: 'Код комиссии',
        dataIndex: 'fee_code',
    },
    {
        title: 'Наименование комиссии',
        dataIndex: 'name',
        render: renderName(feeNames),
    },
    {
        title: 'Минимальная комиссия',
        dataIndex: 'minimum_show',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
    {
        title: '',
        width: '80px',
        dataIndex: 'minimum_show_type',
        render: renderPercentSwitcherMin(onTypeClick, 'minimum_show_type'),
    },
    {
        title: 'Расчётная комиссия',
        dataIndex: 'amount_show',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
    {
        title: '',
        width: '80px',
        dataIndex: 'amount_show_type',
        render: renderPercentSwitcher(onTypeClick, 'amount_show_type'),
    },
    {
        title: 'Максимальная комиссия',
        dataIndex: 'maximum_show',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
    {
        title: '',
        width: '80px',
        dataIndex: 'maximum_show_type',
        render: renderPercentSwitcherMax(onTypeClick, 'maximum_show_type'),
    },

    {
        title: 'error',
        width: '80px',
        dataIndex: 'error',
        render: renderError,
    },
]

export const getTariffViewGridConfig = (): TariffColumnType[] => [
    {
        title: 'Код комиссии',
        dataIndex: 'fee_code',
    },
    {
        title: 'Наименование комиссии',
        dataIndex: 'name',
        render: renderPureName,
    },
    {
        title: 'Минимальная комиссия',
        dataIndex: 'minimum_show',
        render: (value: string, record: IRateBaseFront) =>
            renderValueWithType(record.minimum_show_type, value),
    },

    {
        title: 'Расчётная комиссия',
        dataIndex: 'amount_show',
        render: (value: string, record: IRateBaseFront) =>
            renderValueWithType(record.amount_show_type, value),
    },

    {
        title: 'Максимальная комиссия',
        dataIndex: 'maximum_show',
        render: (value: string, record: IRateBaseFront) =>
            renderValueWithType(record.maximum_show_type, value),
    },
]
