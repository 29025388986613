import { IRateBase, FeeType, IRateBaseFront, rateBackToFront } from '@dltru/dfa-models'

export const mapToDatasource = (rates: IRateBase[], fees: FeeType[]): IRateBaseFront[] => {
    return rates.map((item) => {
        const feeByCode = fees.find((fee) => fee.code === item.fee_code)
        const mappedItem = rateBackToFront(item)

        return { ...mappedItem, name: feeByCode?.name || '' }
    })
}
