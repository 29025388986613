import { IRateBaseFront, IRateBase } from '@dltru/dfa-models'
import { ColumnType } from 'antd/lib/table'
import { FormInstance } from '../../..'
import { TDropdowItem } from '../../../DropdownSelectorCell'

export type TSwitcherCallback = (item: TDropdowItem, name: string, record: IRateBaseFront) => void

export type GridConfigProps = {
    feeNames?: Record<string, string>
    isEditable?: boolean
    onTypeClick: TSwitcherCallback
}
export type EditableTariffColumnType = ColumnType<IRateBaseFront> & { editable?: boolean }
export type TariffColumnType = ColumnType<IRateBaseFront>

export enum ETariffTypeTitle {
    Percent = 'Percent',
    PercentPerYear = 'PercentPerYear',
    Rubles = 'Rubles',
}
