import { IRateBaseFront, stringToNumber } from "@dltru/dfa-models"
import { useContext } from "react"
import { DropdownSelectorCell, TDropdowItem } from "../../../DropdownSelectorCell"
import { getRublesFromPennies } from "../../../utils"
import { itemsFix, itemsPercent } from "./consts"
import { ErrorRender } from "./ErrorRender"
import { TariffRowContext } from "./TariffRowContext"
import { TSwitcherCallback } from "./types"
import style from './style.m.less'
import { FormItem } from "../../../components/Form"

export const renderPureName = (value: string) => value
export const renderName = (feeNames: Record<string, string> | undefined) => (value: string, record: IRateBaseFront) => value || feeNames?.[record.fee_code]

export const renderEditableNumber = (isEditable?: boolean) => (value?: string) =>
    value ? (
        (isEditable ? value : stringToNumber(value) / 100).toLocaleString()
    ) : (
        <span className={style.grayText}>0</span>
    )

export const renderNumber = (value?: string) =>
    value ? (stringToNumber(value) / 100).toLocaleString() : <span className={style.grayText}>0</span >

export const renderPenniesNumber = (value?: number) =>
    value ? getRublesFromPennies(value) : <span className={style.grayText}>0</span>

export const renderPercentSwitcherMin = (onCLick: TSwitcherCallback, name: string) => (value: string, record: IRateBaseFront) => {
    const { onUpdateValue } = useContext(TariffRowContext)
    const fieldName = `${record?.fee_code}${name}`

    const onclickHandler = (item: TDropdowItem) => {
        //onUpdateValue({ [fieldName]: item.value })
        onCLick(item, name, record)
    }

    return (
        <FormItem name={fieldName}>
            <DropdownSelectorCell items={itemsFix} defaultItemKey={value ?? itemsFix[0].key} isChangingLabel onItemClick={onclickHandler} />
        </FormItem>
    )
}

export const renderPercentSwitcherMax = (onCLick: TSwitcherCallback, name: string) => (value: string, record: IRateBaseFront) => {

    const fieldName = `${record?.fee_code}${name}`
    const { onUpdateValue } = useContext(TariffRowContext)

    const onclickHandler = (item: TDropdowItem) => {
        // onUpdateValue({ [fieldName]: item.value })
        onCLick(item, name, record)
    }

    return (
        <FormItem name={fieldName}>
            <DropdownSelectorCell items={itemsFix} defaultItemKey={value ?? itemsFix[0].key} isChangingLabel onItemClick={onclickHandler} />
        </FormItem>)
}
export const renderPercentSwitcher = (onCLick: TSwitcherCallback, name: string) => (value: string, record: IRateBaseFront) => {
    const fieldName = `${record?.fee_code}${name}`
    const { onUpdateValue } = useContext(TariffRowContext)

    const onclickHandler = (item: TDropdowItem) => {
        //onUpdateValue({ [fieldName]: item.value })
        onCLick(item, name, record)
    }

    return (
        <FormItem name={fieldName}>
            <DropdownSelectorCell items={itemsPercent} defaultItemKey={value ?? itemsFix[0].key} isChangingLabel onItemClick={onclickHandler} />
        </FormItem>
    )
}

export const renderError = (value: boolean, record: IRateBaseFront) => {

    const fieldName = `${record?.fee_code}error`

    const amountShow = `${record?.fee_code}amount_show`
    const amountShowType = `${record?.fee_code}amount_show_type`
    const maximiumShow = `${record?.fee_code}maximium_show`
    const maximiumShowType = `${record?.fee_code}maximium_show_type`
    const minimumShow = `${record?.fee_code}minimum_show`
    const minimumShowType = `${record?.fee_code}minimum_show_type`

    const names = { amountShow, amountShowType, maximiumShow, maximiumShowType, minimumShow, minimumShowType }


    return <FormItem name={fieldName}><ErrorRender names={names} feeCode={record.fee_code} showError={value} /></FormItem>
}