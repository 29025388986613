import { FeeType, ITariffPlane } from '@dltru/dfa-models'
import { FC, useState } from 'react'

import { Box } from '../../../Box'
import { Button } from '../../../button'
import { TariffInfoModal } from './TarifInfoModal'

interface IComponentProps {
    data?: ITariffPlane
    fees: FeeType[]
}
export const TariffBaseInfo: FC<IComponentProps> = ({ data, fees }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    if (!data) {
        return null
    }
    return (
        <Box direction="row" justify="right" align="center" gap={4}>
            Остальные комиссии взимаются в соответствии с
            <Button
                type="linkText"
                onClick={() => {
                    setIsModalVisible(true)
                }}
            >
                <strong>
                    Базовым тарифным планом B-{data.code_id}.{data.active_grid?.code_id}
                </strong>
            </Button>
            <TariffInfoModal
                data={data}
                fees={fees}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
            />
        </Box>
    )
}
